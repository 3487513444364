/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .modal {
    background: white;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    max-height: 500px;
    overflow-y: auto;
    position: relative;
  }
  
  /* Close Button */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Modal Title */
  .modal-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }